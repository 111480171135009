import { ConnectionFormComponent } from './../../connection-form/connection-form.component';
import { TaskComponent } from './../../pages/project/task/task.component';
import { MoreTabsModalComponent } from './../../components/more-tabs-modal/more-tabs-modal.component';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs'
import { PersonPage } from 'src/app/pages/person-page/person-page.component';
import { ProjectPage } from 'src/app/pages/project/project.component';
import { InboxPage } from 'src/app/pages/inbox/inbox.component';
import { PersonFormComponent } from 'src/app/components/person-form/person-form.component';
import { IntroComponent } from 'src/app/components/intro/intro.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  floatingActions = new Subject<any[]>();
  hideTabs = new Subject<Boolean>();

  constructor(private modalCtrl: ModalController) { }

  listenToFloatingActions(){
    return this.floatingActions;
  }

  showFloatingActions(actions: {label: string, callback: ()=> void}[]){
    this.floatingActions.next(actions)
  }

  hideFloatingActions(){
    this.floatingActions.next([])
  }

  listenToTabHide(){
    return this.floatingActions;
  }

  setTabVisibility(visible:Boolean){
    this.hideTabs.next(visible)
  }

  async openMorePages() {
    const modal = await this.modalCtrl.create({
      component: MoreTabsModalComponent,
      cssClass: "more-pages-modal",
      componentProps: {

      }
    });
    return await modal.present();
  }

  async openConnectionForm(connection = null, person=null) {
    const modal = await this.modalCtrl.create({
      component: ConnectionFormComponent,
      cssClass: "more-pages-modal",
      componentProps: {
        connection: connection,
        person: person
      }
    });
    await modal.present()
    return modal;
  }

  async openCreatePersonForm(person=null, circle=null) {
    const modal = await this.modalCtrl.create({
      component: PersonFormComponent,
      cssClass: "center-modal",
      componentProps: {
        person: person,
        circle: circle
      }
    });
    await modal.present()
    return modal;
  }

  async openIntro() {
    const modal = await this.modalCtrl.create({
      component: IntroComponent,
      cssClass: "",
    });
    await modal.present()
    return modal;
  }


  // async openPerson(id: any) {
  //   const modal = await this.modalCtrl.create({
  //     component: ProjectPage,
  //     cssClass: ['default-modal', 'ion-disable-focus-trap'],
  //     componentProps: {
  //       'project': {
  //         id: id,
  //       }
  //     }
  //   });
  //   return await modal.present();
  // }

  async openPersonTask(id: any) {
    const modal = await this.modalCtrl.create({
      component: TaskComponent,
      cssClass: ['default-modal', 'ion-disable-focus-trap'],
      componentProps: {
        'task': {
          id: id,
        }
      }
    });
    return await modal.present();
  }

  async openPerson(id: String, summary: {name: String, picture: String} = null) {
    const modal = await this.modalCtrl.create({
      component: PersonPage,
      cssClass: 'default-modal',
      componentProps: {
        'id': id,
        'summary': summary
      }
    });
    await modal.present()
    return modal;
  }

  async openInbox() {
    const modal = await this.modalCtrl.create({
      component: InboxPage,
      cssClass: 'default-modal',
    });
    return await modal.present();
  }
}
