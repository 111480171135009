
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" class="back-button">

      <button nbButton ghost (click)="dismiss()" size="large"  ><nb-icon icon="arrow-ios-back-outline"></nb-icon></button>

    </ion-buttons>
    <ion-title>{{title}}</ion-title>


    <nb-form-field class="due-date" slot="end">
      <nb-icon nbSuffix icon="calendar-outline" pack="eva"></nb-icon>
      <input nbInput slot="end" placeholder="Due Date" [nbDatepicker]="dateTimePicker">
        <nb-datepicker #dateTimePicker></nb-datepicker>
    </nb-form-field>


    <!-- <profile-avatars slot="end" [team]="members"></profile-avatars> -->

  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="task-input">

    <div class="labels mb-2">
      <nb-tag-list>
        <nb-tag *ngFor="let label of labels" appearance="outline" [status]="label?.status" [text]="label?.label"></nb-tag>
      </nb-tag-list>
      <button nbButton  id="task-labels-popover-trigger"  status="basic" size="small" class="action-button ml-2" shape="semi-round"><nb-icon icon="options-2-outline"></nb-icon></button>
      <ion-popover trigger="task-labels-popover-trigger" triggerAction="click" mode="ios"  alignment="center">
        <ng-template>
          <app-task-labels [selectedLabels]="labels" (updateEvent)="handleLabelChange($event)"></app-task-labels>
        </ng-template>
      </ion-popover>
    </div>

    <div class="rich-text">
      <quill-editor [(ngModel)]="body" (onEditorChanged)="debouncedEditHandler($event)"  theme="bubble" [modules]="editorConfig" placeholder="Type details here..."></quill-editor>
    </div>
  </div>

  <h6 class="section-title mt-5 mb-3">Comments</h6>

  <nb-chat class="comments">
    <nb-chat-message
      *ngFor="let msg of comments"
      [type]="msg?.type"
      [message]="msg?.text"
      [reply]="msg?.reply"
      [sender]="msg.user.name"
      [date]="msg.date"
      [files]="msg?.files"
      [quote]="msg?.quote"
      [latitude]="msg?.latitude"
      [longitude]="msg?.longitude"
      [avatar]="msg.user.avatar"
    >
    </nb-chat-message>

    <nb-chat-form [dropFiles]="true"> </nb-chat-form>
  </nb-chat>
</ion-content>
