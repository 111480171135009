import { ConnectionService } from './../services/connection/connection.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Connection } from '../models/connection';
import { AppAlertService } from '../services/app-alert/app-alert.service';
import { Person } from '../models/person';

@Component({
  selector: 'app-connection-form',
  templateUrl: './connection-form.component.html',
  styleUrls: ['./connection-form.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ConnectionFormComponent implements OnInit {

  @Input('connection') connection: Connection;
  @Input('person') person: Person;


  qualityButtons = [
    {
      name: "N/A",
      value: "none"
    },
    {
      name: "Bad",
      value: "bad"
    },
    {
      name: "Okay",
      value: "okay"
    },
    {
      name: "Good",
      value: "good"
    },
    {
      name: "Great",
      value: "great"
    },
  ]

  initiatorButtons = [
    {
      name: "N/A",
      value: "none"
    },
    {
      name: "I did",
      value: "me"
    },
    {
      name: "They did",
      value: "them"
    }
  ]

  mediumButtons = [
    {
      name: "Text",
      value: "message"
    },
    {
      name: "Call",
      value: "call"
    },
    {
      name: "IRL",
      value: "irl"
    },
  ]

  setValue = {
    quality: null,
    initiator: null,
    medium: null
  }

  multiSelectGroupValue = [];


  constructor(
    private modalCtrl: ModalController,
    private cd: ChangeDetectorRef,
    private connectionService: ConnectionService,
    private alertService: AppAlertService
  ) { }

  ngOnInit() {
    this.cd.markForCheck();

    if (this.connection){
      this.setValue = {
        quality: this.connection.quality,
        initiator: this.connection.initiator,
        medium: this.connection.medium
      }
    }

  }

  dismiss(){
  	this.modalCtrl.dismiss();
  }

  updateValue(value, type): void{
    if (type=='quality') {
      this.setValue.quality = value[0];
    }
    if (type=='initiator') {
      this.setValue.initiator = value[0];
    }
    if (type=='medium') {
      this.setValue.medium = value[0];
    }
    // this.cd.markForCheck();
  }

  save(){

    let savedConnection = {
      quality: this.setValue.quality,
      initiator: this.setValue.initiator,
      medium: this.setValue.medium,
      person: this.person,
      date: null
    }

    savedConnection.date = this.connection?.date

    if (this.connection){
      this.connectionService.editConnection(this.connection.id, savedConnection).then(()=>{
        this.alertService.showSuccessAlert("Saved Successfully")
        this.dismiss()
      }).catch((e)=>{
        this.alertService.showErrorAlert("Failed to Save: " + e?.message)
      })
    }
    else {
      savedConnection.date = new Date().toISOString();
      this.connectionService.createConnection(savedConnection).then(()=>{
        this.alertService.showSuccessAlert("Saved Successfully")
        this.dismiss()
      }).catch((e)=>{
        this.alertService.showErrorAlert("Failed to Save: " + e?.message)
      })
    }
  }

  delete(){
    this.connectionService.deleteConnection(this.connection.id).then(()=>{
      this.alertService.showSuccessAlert("Deleted Successfully")
      this.dismiss()
    }).catch((e)=>{
      this.alertService.showErrorAlert("Failed to delete: " + e?.message)
    })
  }
}
