import { mapPerson, Person } from "./person";
import * as dayjs from 'dayjs';
import * as calendar from 'dayjs/plugin/calendar';

import { orderbyNearestDate } from "../services/utils/utils.service"

dayjs.extend(calendar);

let dayJsConfig = {
  sameDay: '[Today]',
  lastDay: '[Yesterday]',
  lastWeek: 'dddd',
  sameYear: 'MM D',
  sameElse: 'MMM D'
}

export interface Connection {
  id: string;
  quality: string;
  initiator: string;
  medium: string;
  person: string | Person;
  createdDate: Date;
  lastEdited: Date;
  connectionMsg: string;
  date: Date;
  dateString: string;
}

let connectionMediumMsg = {
  message: 'messaged',
  call: 'called',
  irl: 'met IRL',
  none: 'connected'
}

export function mapConnections(connections): Connection[] {

  let list = connections?.data?.map(connection => mapEntity(connection))
  list = orderbyNearestDate(list, 'date');
  return list;
}

export function mapConnection(connection): Connection {

  return mapEntity(connection?.data)
}

export function mapEntity(connection): Connection {

  let obj = connection.attributes;
  obj.id = connection.id;

  if (obj.initiator == 'me' && obj?.medium)
    obj.connectionMsg = `I ${connectionMediumMsg[obj?.medium]}`;
  else if (obj.initiator == 'them' && obj?.medium)
    obj.connectionMsg = `They ${connectionMediumMsg[obj?.medium]}`;
  else
    obj.connectionMsg = `${connectionMediumMsg[obj?.medium]}`;

  obj.dateString = obj?.date ? dayjs(obj.date).calendar(null, dayJsConfig) : null;
  // obj.dateString = dayjs().calendar(dayjs(obj.date), dayJsConfig)
  obj.dateString = obj.dateString.substr(0,obj.dateString.indexOf("at")-1) || obj.dateString; 
  
  obj.person =  (obj?.person) ? mapPerson(obj.person) : null;
  return obj;
}

