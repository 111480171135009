<nb-card class="bottom-modal purple-pink-card">
  <nb-icon class="close" icon="close" (click)="dismiss()"></nb-icon>
  <nb-card-body class="justify-content-start px-1 pt-0">

    <div class="section-header mt-0 mb-2 w-100 justify-content-start mx-0">
      <h6 class="section-title">Quality</h6>
    </div>
    <nb-button-group outline class="w-100" (valueChange)="updateValue($event, 'quality')">
      <button *ngFor="let button of qualityButtons" [value]="button.value" [pressed]="button.value == setValue.quality" nbButtonToggle class="w-100" nbButton size="small" shape="round"  status="primary">
        {{button.name}}
      </button>
    </nb-button-group>

    <div class="section-header mt-1 mb-2 w-100 justify-content-start mx-0">
      <h6 class="section-title mt-2">Initiator</h6>
    </div>
    <nb-button-group outline class="w-100" (valueChange)="updateValue($event, 'initiator')">
      <button *ngFor="let button of initiatorButtons" [value]="button.value" [pressed]="(button.value == setValue.initiator)" nbButtonToggle class="w-100" nbButton size="small" shape="round"  status="primary">
        {{button.name}}
      </button>
    </nb-button-group>

    <div class="section-header mt-1 mb-2 w-100 justify-content-start mx-0 w-100">
      <h6 class="section-title mt-2">Medium</h6>
    </div>
    <nb-button-group outline class="mb-2 w-100" (valueChange)="updateValue($event, 'medium')">
      <button *ngFor="let button of mediumButtons" [value]="button.value" [pressed]="button.value == setValue.medium" nbButtonToggle class="w-100" nbButton size="small" shape="round"  status="primary">
        {{button.name}}
      </button>
    </nb-button-group>

    <div class="d-flex w-100 align-items-center">
      <button *ngIf="connection" (click)="delete()" class="page-button mt-3 mb-0 mr-3" nbButtonToggle nbButton size="large" shape="round" outline status="danger">
        <nb-icon icon="trash-2-outline" class="mr-2"></nb-icon> Delete
      </button>
      <button (click)="save()" class="full-button page-button mt-3 mb-0" fullWidth nbButton size="large" shape="round" outline status="primary">
        <nb-icon icon="plus-outline" class="mr-2"></nb-icon>Save Connection
      </button>
    </div>

  </nb-card-body>
</nb-card>
