import { map } from 'rxjs/operators';
import { mapConnections } from './connection';

export let CIRCLE_NAMES = {
  inner: "Inner Circle",
  close: "Close Circle",
  casual: "Casual Circle"
}


export interface Person {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dob?: Date;
  phone?: string;
  picture?: string;
  gender?: string;
  connections?: any[];
  description?: string;
  notes?: any[];
  circle?: string;
  circleName?: string;
  contactPeriod?: string;
}

export function mapPeople(people): Person[] {

  let list = people?.data?.map(person => mapEntity(person))
  return list;
}

export function mapPerson(person): Person {

  if (person?.data) return mapEntity(person?.data)
  else mapEntity(person)
}

export function mapEntity(person): Person {

  let obj;

  if (person.attributes) obj = person.attributes;
  else obj = person;

  obj.id = person.id;

  obj.circleName = CIRCLE_NAMES[obj?.circle];
  obj.name = `${obj.firstName} ${obj.lastName}`;
  if (obj?.contactPeriod == "bi_weekly") obj.contactPeriod = 'bi-weekly';

  obj.connections = mapConnections(obj?.connections)
  return obj;
}

