import { PersonService } from '../../../services/person/person.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { reject, filter } from 'lodash';

@Component({
  selector: 'app-task-labels',
  templateUrl: './task-labels.component.html',
  styleUrls: ['./task-labels.component.scss'],
  // encapsulation: ViewEncapsulation.None

})
export class TaskLabelsComponent implements OnInit {

  @Input('selectedLabels') selectedLabels: any[];
  allLabelsOriginal: any[];
  otherLabels: any[]
  @Output('updateEvent') updateEvent = new EventEmitter<any[]>();

  constructor(private PersonService: PersonService) { }

  async ngOnInit() {
    // this.allLabelsOriginal = await this.PersonService.getLabels(null)
    this.removeDuplicateLabels()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedLabels) {
      this.removeDuplicateLabels()
    }
  }

  toggle(label){
    label.checked = !label.checked ?? true;
    this.emitNewList()
  }

  removeDuplicateLabels(){
    this.otherLabels = this.allLabelsOriginal;
    this.selectedLabels.forEach(label=>{
      this.otherLabels = reject(this.otherLabels, {label: label.label})
      label.checked = true;
    })
  }

  emitNewList(){
    let newList = filter(this.selectedLabels, {checked: true});
    newList.push(...filter(this.otherLabels, {checked: true}));
    this.updateEvent.emit(newList)
  }

}
