
<ion-header>
  <ion-toolbar mode="md">
    <ion-buttons slot="start" class="back-button">

      <button nbButton ghost (click)="dismiss()" size="large" class="px-2"><nb-icon icon="arrow-ios-back-outline"></nb-icon></button>
    </ion-buttons>
    <ion-title>{{(person)? 'Update Contact' : 'Create Contact'}}</ion-title>

    <ion-buttons slot="end">
      <button (click)="save()" nbButton outline shape="round" status="basic" class="font-weight-normal mr-1">
        Save
      </button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>

  <div class="person-modal-content mt-4">
    <input type="text" nbInput fullWidth  placeholder="First Name" class="mb-2 mt-2" [formControl]="firstName">

    <input type="text" nbInput fullWidth  placeholder="Last Name" class="mb-2 mt-2" [formControl]="lastName">

    <nb-select *ngIf="!person" placeholder="Select Circle" fullWidth [formControl]="circleForm" class="mb-2 mt-2 ">
      <nb-option value="inner">Inner Circle</nb-option>
      <nb-option value="close">Close Circle</nb-option>
      <nb-option value="casual">Casual Circle</nb-option>
    </nb-select>

    <hr class="mt-5"/>
    <button *ngIf="person" nbButton outline status="danger" class="mt-5" (click)="delete()">Delete person</button>

  </div>
