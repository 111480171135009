import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-more-tabs-modal',
  templateUrl: './more-tabs-modal.component.html',
  styleUrls: ['./more-tabs-modal.component.scss'],
})
export class MoreTabsModalComponent implements OnInit {

  apuaPages = [
    { name: "Internal Directory", icon: "people-outline"},
    { name: "Photo Album", icon: "bulb-outline"},
    { name: "Employee Handbook", icon: "book-outline"},
    { name: "Policies and Forms", icon: "info-outline"},
    { name: "Support and FAQ", icon: "question-mark-circle-outline"}
  ]

  pages = [
    { name: "Internal Directory", icon: "people-outline"},
    { name: "Forms", icon: "info-outline"},
    { name: "Support and FAQ", icon: "question-mark-circle-outline"}
  ]

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss(){
  	this.modalCtrl.dismiss();
  }

}
