<h1 class="title">Guildmates</h1>
<ion-slides pager="true"  [options]="slideOpts">
  <ion-slide>
    <h1 class="mx-3">Circles</h1>
    <p class="mx-3">
      Put life into perspective by identifying your inner circle, close and casual friends.
    </p>
    <img src="../../../assets/img/intro1.svg">
  </ion-slide>
  <ion-slide>
    <h1 class="mx-3">Countdowns</h1>
    <p class="mx-3">
      Never lose touch with a friend again with focused reminders
    </p>
    <img src="../../../assets/img/intro2.svg">  </ion-slide>
  <ion-slide>
    <h1 class="mx-3">Connections</h1>
    <p class="mx-3">
      Build better connections through reflecting on your high quality interactions
    </p>
    <img class="px-4" src="../../../assets/img/intro3.svg">
    <button class="full-button page-button" (click)="dismiss()" nbButton size="large" shape="round" status="basic">Get Started</button>

  </ion-slide>
  <!-- <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>
  <div class="swiper-scrollbar"></div> -->

</ion-slides>