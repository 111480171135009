<ion-backdrop *ngIf="showBackdrop" [visible]="true"></ion-backdrop>
<ion-toolbar mode="md">
  <ion-buttons slot="start" class="back-button">

    <button nbButton ghost (click)="dismiss()" size="large" class="px-2"><nb-icon icon="arrow-ios-back-outline"></nb-icon></button>
    <profile-avatars (click)="editPerson()" [team]="person?.picture"></profile-avatars>
  </ion-buttons>
  <ion-title (click)="editPerson()">{{person?.firstName}} {{person?.lastName}}</ion-title>

  <ion-buttons slot="end">
    <button *ngIf="pendingChanges" (click)="savePerson()" nbButton outline shape="round" status="basic" class="font-weight-normal mr-1">
      Save
    </button>
  </ion-buttons>

</ion-toolbar>
<ion-content>
<ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
  <ion-refresher-content></ion-refresher-content>
</ion-refresher>

<div class="settings">
  <button fullWidth nbButton (click)="changeCircle(person?.circle)" shape="round" size="small" status="basic" class="mx-2">{{person?.circleName ?? 'Select Circle'}} <nb-icon icon="people-outline"></nb-icon></button>
  <button  fullWidth nbButton (click)="changeContactPeriod(person?.contactPeriod)" shape="round" size="small" status="basic" class="mx-0">{{person?.contactPeriod ?? 'Select Period'}} <nb-icon icon="calendar-outline"></nb-icon></button>
  <button fullWidth nbButton id="open-date-modal" shape="round" size="small" status="basic" class="">{{(person?.dob | date: 'LLL d') ?? 'Select DOB'}} <nb-icon icon="gift-outline"></nb-icon></button>
</div>


<swipe-nav (buttonClicked)="buttonClicked($event)" (tabChange)="goToTab($event)" [currentTab]="activeTab" [tabs]="nav.tabs" class="mt-3"></swipe-nav>
<div class="divider mx-4 py-1"></div>
<swipe-tabs [selectedTabIndex]="activeTab" (currentTabChange)="setCurrentTab($event)">
  <ion-slide>
    <section class="full">

          <connection-list [connections]="person?.connections" [person]="person?.id" (refresh)="refreshConnections($event)"></connection-list>

    </section>
  </ion-slide>
  <ion-slide>
    <section class="full chat">
      <div class="w-100">
      <div class="section-header mt-3 px-2">
        <h6 class="section-title">Description</h6>
      </div>
      <textarea [formControl]="descriptionInput" type="text" class="mx-4 mb-4 p-2" rows="5" nbInput fullWidth fieldSize="giant" placeholder="Enter description or notes here.">
      </textarea>

      <!-- <div class="section-header">
        <h6 class="section-title">Notes <span class="section-alerts">(3)</span></h6>
      </div>
      <nb-chat>
        <nb-chat-message
          *ngFor="let msg of messages"
          [type]="msg?.type"
          [message]="msg?.text"
          [reply]="msg?.reply"
          [sender]="msg.user.name"
          [date]="msg.date"
          [files]="msg?.files"
          [quote]="msg?.quote"
          [latitude]="msg?.latitude"
          [longitude]="msg?.longitude"
          [avatar]="msg.user.avatar"
        >
        </nb-chat-message>

      </nb-chat> -->
      </div>

      <!-- <ion-fab vertical="bottom" horizontal="center">
        <nb-chat-form (send)="sendMessage($event)" [dropFiles]="false"> </nb-chat-form>
      </ion-fab> -->

    </section>
  </ion-slide>
</swipe-tabs>


<ion-modal trigger="open-date-modal" [breakpoints]="[0,0.3]" [initialBreakpoint]="0.3" [backdropBreakpoint]="0.2" (willDismiss)="onDismissDateModal($event)" [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime id="datetime" presentation="date" [preferWheel]="true" [showDefaultButtons]="true" size="cover" [value]="person?.dob" (ionChange)="changeDOB($event)"></ion-datetime>
  </ng-template>
</ion-modal>

<ion-fab slot="fixed" vertical="bottom" horizontal="center" (click)="newConnection()">
  <button class="full-button page-button" nbButton size="giant" shape="round" outline status="primary"><nb-icon icon="plus-outline"></nb-icon>New Connection</button>
</ion-fab>

</ion-content>