import { AppAlertService } from './../../services/app-alert/app-alert.service';
import { FormBuilder } from '@angular/forms';
import { PersonService } from './../../services/person/person.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { Person, CIRCLE_NAMES } from 'src/app/models/person';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ActionSheetController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';


@Component({
  selector: 'app-person-page',
  templateUrl: './person-page.component.html',
  styleUrls: ['./person-page.component.scss'],
})
export class PersonPage implements OnInit {


  @Input('id') id: string;
  @Input('summary') summary: {
    name: string,
    picture: string
  };
  @ViewChild(IonModal) modal: IonModal;


  activeTab = 0;

  nav = {

    tabs: [
      {label: "Connections", selected: true},
      {label: "Profile", selected: false},
    ]
  }

  messages: any = [
    {
      text: 'How do you like the latest app updates?',
      date: new Date(),
      reply: false,
      user: {
        name: 'Regis Burton',
        avatar: 'assets/avatars/M1.jpg',
      },
    },
    {
      text: 'Looks fantastic, I really like seeing the project timelines in my calender',
      date: new Date(),
      reply: true,
      user: {
        name: 'Imani Jacob',
        avatar: 'assets/avatars/F1.jpg',
      },
    },
    {
      text: 'Great to hear!',
      date: new Date(),
      reply: false,
      user: {
        name: 'Regis Burton',
        avatar: 'assets/avatars/M1.jpg',
      },
    },
  ]

  showAddConnection = false;

  person: Person;
  descriptionInput = this.fb.control('');
  pendingChanges = false;
  newChanges: Person;
  showBackdrop = false;

  constructor(
    private modalCtrl: ModalController,
    private modalService: ModalService,
    private personService: PersonService,
    private fb: FormBuilder,
    private actionSheetCtrl: ActionSheetController,
    private alertService: AppAlertService
  ) { }

  async ngOnInit() {
    await this.loadPerson()

    this.descriptionInput = this.fb.control(this.person.description)

    this.newChanges = {
      id: this.id
    }

    this.descriptionInput.valueChanges.subscribe({

      next: (val)=>{

        if (val != this.person.description) {
          this.newChanges.description = val;
          this.pendingChanges = true;
          this.person.description = val;
        }

    }})
  }

  async loadPerson(){
    this.person = await this.personService.getPerson(this.id)
    return this.person;
  }

  async handleRefresh(event){
    
    await this.loadPerson()
    event.target.complete();
  }
  
  sendMessage(event: any) {
    const files = !event.files ? [] : event.files.map((file) => {
      return {
        url: file.src,
        type: file.type,
        icon: 'file-text-outline',
      };
    });

    this.messages.push({
      text: event.message,
      date: new Date(),
      reply: true,
      type: files.length ? 'file' : 'text',
      files: files,
      user: {
        name: 'Jonh Doe',
        avatar: 'https://i.gifer.com/no.gif',
      },
    });

  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


  goToTab(i){
    this.activeTab = i;
  }

  setCurrentTab(index){
    this.activeTab = index;
  }

  buttonClicked(button){
    if (button=='inbox'){
      // this.modalService.openInbox()
    }
  }


  async newConnection(){
    let modal = await this.modalService.openConnectionForm(null, this.person.id)
    const { data, role } = await modal.onWillDismiss();
    this.loadPerson()
  }

  async changeCircle(selected=null) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Circle',
      buttons: [
        {
          text: 'Inner Circle',
          role: selected == 'inner' ?  'selected' : null,
          data: {
            action: 'inner',
          },
        },
        {
          text: 'Close Circle',
          role: selected == 'close' ?  'selected' : null,
          data: {
            action: 'close',
          },
        },
        {
          text: 'Casual Circle',
          role: selected == 'casual' ?  'selected' : null,
          data: {
            action: 'casual',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });

    await actionSheet.present();

    let result = await actionSheet.onDidDismiss();
    let action = result.data.action;

    if (action != this.person.circle) {
      this.newChanges.circle = action;
      this.pendingChanges = true;
      this.person.circle = action;
      this.person.circleName = CIRCLE_NAMES[action];
    }
  }

  async changeContactPeriod(selected=null) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Contact Period',
      buttons: [
        {
          text: 'Daily',
          role: selected == 'daily' ?  'selected' : null,
          data: {
            action: 'daily',
          },
        },
        {
          text: 'Weekly',
          role: selected == 'weekly' ?  'selected' : null,
          data: {
            action: 'weekly',
          },
        },
        {
          text: 'Bi-weekly',
          role: selected == 'bi-weekly' ?  'selected' : null,
          data: {
            action: 'bi-weekly',
          },
        },
        {
          text: 'Monthly',
          role: selected == 'monthly' ?  'selected' : null,
          data: {
            action: 'monthly',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });

    await actionSheet.present();

    let result = await actionSheet.onDidDismiss();
    let action = result.data.action;

    if (action != this.person.contactPeriod) {
      this.newChanges.contactPeriod = action;
      this.pendingChanges = true;
      this.person.contactPeriod = action;

    }

  }

  async changeDOB(event) {

    let action = event?.detail?.value;

    if (action != this.person.dob) {
      this.newChanges.dob = action;
      this.pendingChanges = true;
      this.person.dob = action;
    }

  }

  savePerson(){
    this.personService.editPerson(this.id, this.person).then(()=>{
      this.alertService.showSuccessAlert("Saved Successfully")
      this.pendingChanges = false;
      this.newChanges = {
        id: this.id
      }
    }).catch((e)=>{
      this.alertService.showErrorAlert("Failed to Save: " + e?.message)
    })
  }

  onDismissDateModal(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      this.person.dob = new Date(ev.detail.data);
    }
  }

  async editPerson(){
    this.showBackdrop = true;
    let modal = await this.modalService.openCreatePersonForm(this.person)
    const { data, role } = await modal.onWillDismiss();

    if (data == 'deleted') this.dismiss()

    this.showBackdrop = false;
  }

  refreshConnections(doRefresh: boolean){
    this.loadPerson()
  }


}
