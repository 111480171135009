import { map } from 'rxjs/operators';
import { PersonService } from 'src/app/services/person/person.service';
import { TaskLabelsComponent } from '../task-labels/task-labels.component';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { take, debounce } from 'lodash';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  // encapsulation: ViewEncapsulation.None

})
export class TaskComponent implements OnInit {

  @Input('id') id: any = null;
  @Input('title') title: String = "Make Bad Coffee";
  @Input('body') body: String;
  @Input('color') color: string = 'light';
  @Input('textColor') textColor: string = 'dark';
  @Input('commentNum') commentNum: Number;
  @Input('labels') labels: Array<any>;

  @Input('members') members: any = [];
  taskLabelsComponent = TaskLabelsComponent;
  comments: any;

  isEditing: boolean = true;
  editorConfig = {
    toolbar: [['bold', 'italic', 'underline', 'strike'],   [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }], ['link',]],
  }
  noteContents: string;
  noteTitle: string;
  debouncedEditHandler = (v)=>{};

  constructor(private modalCtrl: ModalController, private PersonService: PersonService) { }

  async ngOnInit() {
    this.members = [
      {
        name: "Jonh Doe",
        picture: "assets/avatars/F1.jpg"
      },
      {
        name: "Jonh Doe",
        picture: "assets/avatars/F1.jpg"
      },
      {
        name: "Jonh Doe",
        picture: "assets/avatars/F1.jpg"
      }
    ]

    // this.labels = take(await this.PersonService.getLabels(this.id),2)

    // this.debouncedEditHandler = debounce(this.changedEditor, 5000, { 'maxWait': 15000, trailing: true})
    // this.comments = await this.PersonService.getTaskComments(this.id);
  }


  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  handleLabelChange(value){
    this.labels = value;
  }

  changedEditor(event: EditorChangeContent) {
    // tslint:disable-next-line:no-console
    this.handleSave(event?.html)

  }

  handleSave(content){
    content;
  }


}
