<app-header
  title="Messages"
  subtitle="6 Unread"
  [showSearch]="false"
  showBackButton="true"
  (back)="dismiss()"
></app-header>

<ion-content [fullscreen]="true">
  <swipe-nav (tabChange)="goToTab($event)" [currentTab]="activeTab" [tabs]="nav.tabs" [buttons]="nav.buttons"></swipe-nav>
  <swipe-tabs
    [selectedTabIndex]="activeTab"
    (currentTabChange)="setCurrentTab($event)"
  >
    <ion-slide>
      <section class="full">

        <nb-list class="main-list">
          <nb-list-item *ngFor="let chat of chats" class="list-item unread" (click)="openChat('null', chat.name)">
            <item-avatar [user]="{picture: chat.avatar}"></item-avatar>
            <ion-grid>
              <ion-row >
                <ion-col class="ion-justify-content-start">
                  <p class="title">{{chat.name}}</p>
                </ion-col>
                <ion-col class=" ion-text-end">
                  <span class="text-muted time"> {{ chat?.lastMessage?.date | date: 'shortTime'}} </span>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-start" >
                <p class="detail ion-text-nowrap">{{ chat?.lastMessage?.message }}</p>
              </ion-row>
            </ion-grid>
          </nb-list-item>
        </nb-list>

      </section>
    </ion-slide>
    <ion-slide>
      <section class="full"></section>
    </ion-slide>
  </swipe-tabs>
</ion-content>
