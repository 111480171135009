import { PersonPage } from '../person-page/person-page.component';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxPage implements OnInit {

  exdate = new Date()
  activeTab = 0;

  nav = {
    buttons: [
       {
         icon: 'search',
         event: 'search'
       },
       {
         icon: 'edit-outline',
         event: 'create',
         label: 'new'
       }

     ],

     tabs: [
       {label: "Inbox", notices: 4, selected: true},
       {label: "Message Request", notices: 1, selected: false},
       {label: "Archieved", selected: false},
     ]
   }

  chats = [
    {
      avatar: "assets/avatars/M2.jpg",
      lastMessage: {
        message: "Hey, reminder to review the docs",
        date: new Date()
      },
      name: "Jonn Snow"
    },
    {
      avatar: "assets/avatars/M1.jpg",
      lastMessage: {
        message: "Great to Hear!",
        date: new Date()
      },
      name: "Regis Burton"
    },
    {
      avatar: "assets/avatars/F3.jpg",
      lastMessage: {
        message: "These designs look great!",
        date: new Date()
      },
      name: "Susan Lake"
    },
  ]

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  goToTab(i){
    this.activeTab = i;
  }

  setCurrentTab(index){
    this.activeTab = index;
  }

  async openChat(id: String, name: String) {
    const modal = await this.modalCtrl.create({
      component: PersonPage,
      cssClass: 'my-custom-class',
      componentProps: {
        'chatSummary': {
          id: id,
          name: name
        }
      }
    });
    return await modal.present();
  }

  dismiss(data=false) {
    this.modalCtrl.dismiss(data);
  }

}
