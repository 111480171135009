<nb-card class="m-0">
  <nb-card-header class="d-flex ion-justify-content-between ">
    Labels
    <button nbButton status="basic" ghost class="p-0"><nb-icon icon="edit-outline"></nb-icon></button>
  </nb-card-header>
  <nb-list>
    <nb-list-item *ngFor="let label of selectedLabels" >
      <nb-checkbox  [status]="label?.status" checked (checkedChange)="toggle(label)">{{label?.label ?? "No label"}}</nb-checkbox>
    </nb-list-item>
    <nb-list-item *ngFor="let label of otherLabels">
      <nb-checkbox  [status]="label?.status" (checkedChange)="toggle(label)">{{label?.label ?? "No label"}}</nb-checkbox>
    </nb-list-item>
  </nb-list>
</nb-card>

