import { reject } from 'lodash';
import { UserStore } from './../../@auth/user.store';
import { map } from 'rxjs/operators';
import { UtilsService } from './../utils/utils.service';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../modal/modal.service';
import { Person, mapPeople, mapPerson } from 'src/app/models/person';
import { Apollo, gql } from 'apollo-angular';
import { cloneDeep } from "lodash";
@Injectable({
  providedIn: 'root'
})
export class PersonService {


  entityName = 'people'
  constructor(
    private modalCtrl: ModalController,
    private modalService: ModalService,
    private utilsService: UtilsService,
    private apollo: Apollo,
    private userStore: UserStore

  ) {  }

  openPerson(id, data=null){
    return this.modalService.openPerson(id, data)
  }

  async getMyPeople(): Promise<Person[]>{
    return new Promise ((resolve, reject)=> {
      let userId = this.userStore.getUser().id
      let getPeopleGQL = gql ` query {
        people(filters: { user: { id: { eq: ${userId} } } }) {
          data {
            id
            attributes {
              firstName
              lastName
              user {
                data {
                  id
                }
              }

              contactPeriod
              circle
              uid
              createdAt
              updatedAt
              connections(sort: ["date:desc"], pagination: { pageSize: 1 }) {
                data {
                id
                  attributes {
                    date
                  }
                }
              }
            }
          }
        }
      }
      `;
      return this.apollo
      .watchQuery({
        query: getPeopleGQL,
      })
      .valueChanges.subscribe(({data, error} : any) => {
       if (error) reject(error)
       else return resolve(mapPeople(cloneDeep(data.people)))
      })

    })
  }

  async getMyPeopleOld(): Promise<Person[]>{

    let user = await this.utilsService.makeGetRequest(`users/me?populate=people`)


    return mapPeople({
      data: user?.people
    })
  }

  async getPerson(id): Promise<Person>{
    return mapPerson(await this.utilsService.makeGetRequest(`${this.entityName}/${id}?populate[connections][populate][0]=person`))
  }

  async listPeople(): Promise<Person[]>{
    return mapPeople(await this.utilsService.makeGetRequest(`${this.entityName}`))
  }

  editPerson(id, data: Object):Promise<Person>{
    return this.utilsService.makePutRequest(`${this.entityName}/${id}`,data)
  }

  deletePerson(id){
    return this.utilsService.makeDeleteRequest(`${this.entityName}/${id}`)
  }

  async createPerson(data):Promise<Person>{
    return await mapPerson(await this.utilsService.makePostRequest(`${this.entityName}`,data))
  }

}

