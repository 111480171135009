import { Component, OnInit } from '@angular/core';
import { NotificationService } from './services/notification/notification.service';
import { ModalService } from './services/modal/modal.service';
import { StorageService } from './services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private notifService: NotificationService,
    private modalService: ModalService,
    private storageService: StorageService
  ) {}

  initaited = false;

  async ngOnInit(){

    if (!this.initaited){
      this.notifService.initNotifs();

      if (! await(this.storageService.get('hideIntro'))){
        this.modalService.openIntro()
        this.storageService.set('hideIntro', true)
      }
      this.initaited = true;
    }
  }

}
