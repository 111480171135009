import { ModalService } from 'src/app/services/modal/modal.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AppAlertService } from 'src/app/services/app-alert/app-alert.service';
import { PersonService } from 'src/app/services/person/person.service';
import { Person } from 'src/app/models/person';
import { UserService } from 'src/app/services/user/user.service';
import { UserStore } from 'src/app/@auth/user.store';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
})
export class PersonFormComponent implements OnInit {

  firstName = this.fb.control('');
  lastName = this.fb.control('');
  circleForm = this.fb.control('');

  @Input('person') person: Person;
  @Input('circle') circle: string;

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private personService: PersonService,
    private alertService: AppAlertService,
    private modalService: ModalService,
    private userStore: UserStore
  ) { }

  ngOnInit() {
    if (this.person){
      this.firstName = this.fb.control(this.person.firstName);
      this.lastName = this.fb.control(this.person.lastName);
    }
    console.log(this.circle)
    if (this.circle && !this.person) this.circleForm = this.fb.control(this.circle);
  }

  save(){
    if (!this.circleForm.value) return this.alertService.showErrorAlert('Circle needs to be selected')

    if (!this.person) {
      this.personService.createPerson(
        {
          firstName: this.firstName.value,
          lastName: this.lastName.value,
          circle: this.circleForm.value,
          user: this.userStore.getUser().id
        }
      ).then((person)=>{
        this.alertService.showSuccessAlert("Created Successfully", "bottom")
        this.dismiss()
        this.modalService.openPerson(person.id)
      }).catch((e)=>{
        this.alertService.showErrorAlert("Failed to Save: " + e?.message)
        this.dismiss()
      })
    }
    else {
      this.personService.editPerson( this.person.id,
        {
          firstName: this.firstName.value,
          lastName: this.lastName.value,
        }
      ).then((person)=>{
        this.alertService.showSuccessAlert("Saved Successfully", "bottom")
        this.dismiss()
      }).catch((e)=>{
        this.alertService.showErrorAlert("Failed to Save: " + e?.message)
        this.dismiss()
      })
    }
  }

  dismiss(data = null) {
    this.modalCtrl.dismiss({
      'dismissed': true,
      'data': data
    });
  }

  delete(){
    this.personService.deletePerson(this.person.id).then(()=>{
      this.alertService.showSuccessAlert("Deleted Successfully", "bottom")
      this.dismiss('deleted')
    }).catch((e)=>{
      this.alertService.showErrorAlert("Failed to Delete: " + e?.message)
      this.dismiss()
    })
  }

}
