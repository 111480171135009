import { map } from 'rxjs/operators';
import { UtilsService } from '../utils/utils.service';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../modal/modal.service';
import { Connection, mapConnection } from 'src/app/models/connection';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {


  entityName = 'connections'
  constructor(
    private modalCtrl: ModalController,
    private modalService: ModalService,
    private utilsService: UtilsService

  ) {  }

  openConnection(id, data=null){
    // return this.modalService.openConnection(id, data)
  }

  async getConnection(id): Promise<Connection>{
    return mapConnection(await this.utilsService.makeGetRequest(`${this.entityName}/${id}?populate=*`))
  }

  editConnection(id, data: Object):Promise<Connection>{
    return this.utilsService.makePutRequest(`${this.entityName}/${id}`,data)
  }

  deleteConnection(id){
    return this.utilsService.makeDeleteRequest(`${this.entityName}/${id}`)
  }

  createConnection(data):Promise<Connection>{
    return this.utilsService.makePostRequest(`${this.entityName}`,data)
  }

}

