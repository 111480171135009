import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit {

  slideOpts = {
    initialSlide: 0,
    speed: 400,
    // paginationClickable: true, 
    // pagination: true
  };

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  dismiss(){
  	this.modalCtrl.dismiss();
  }

}
